import React, {Component} from 'react';
import CountDown from 'reactjs-countdown';
import './style.css'

class Saveday extends Component {

    render() {
        return (
            <div className={`saveday ${this.props.saveday}`}>
                <div className="countdownwrap">
                    <CountDown
                        deadline="September 10, 2022"
                    />
                </div>
            </div>
        );
    }
}

export default Saveday;