import React from 'react';
import bvg from '../../images/transport/bvg.png'
import bolt from '../../images/transport/bolt.png'
import freenow from '../../images/transport/freenow.png'
import uber from '../../images/transport/uber.png'
import hab from '../../images/transport/hab.jpg'
import SectionTitle from '../section-title'
import './style.css'


const Getting = () => {
    return (
        <div className="gta-area section-padding" id="transport">
            <SectionTitle section={'Transport & Unterkunft'}/>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                        <div className="row">
                            <div className="heading col-md-12 col-sm-6">
                                <h4>Transport</h4>
                                <div className="gta-content">
                                    <p>Um die gemeinsame Anreise zur Location zu vereinfachen, wird es einen Bus geben,
                                        der Euch von der Kirche zur Partylocation transportieren wird. Bitte beachtet,
                                        dass die Parkplätze rund um die Kirche begrenzt sind. Direkt am Seepavillon gibt
                                        es aber genug Parkplätze für Autos. <br/><br/> Damit ihr nach erfolgreicher
                                        Feier und Alkoholkonsum sicher nach ause kommt, steht ab <b>23.00 Uhr ein
                                            Shuttle bereit</b>, dass euch zum nächstgelegenen U-Bahnhof (U Alt-Tegel)
                                        und nahe gelegenden Hotels/Unterkünften bringen wird.
                                        <br/><br/>
                                        Außerdem bietet Berlin weitere Mobilitätsangebote, falls Ihr Abends preiswert in
                                        eure Unterkunft möchtet:
                                    </p>
                                    <br/><br/>
                                    <ul>
                                        <li className="row">
                                            <div className="col-md-1 col-sm-1">
                                                <img src={bvg} style={{width: "40px"}} alt="BVG"/>
                                            </div>
                                            <div className="col-md-11 col-sm-11">
                                                BVG: Über die Apps der Berliner Verkehrsbetriebe könnt ihr leicht
                                                eure U-, S- und Busverbindungen einsehen, ihr könnt diese hier
                                                für <a
                                                href={"https://play.google.com/store/apps/details?id=de.eos.uptrade.android.fahrinfo.berlin&hl=de"}
                                                target="_blank" rel="noreferrer">Android</a> oder <a
                                                href={"https://apps.apple.com/de/app/fahrinfo-berlin/id284971745"}
                                                target="_blank" rel="noreferrer">iPhone</a> herunterladen.
                                            </div>
                                        </li>
                                        <hr/>
                                        <li className="row">
                                            <div className="col-md-1 col-sm-1">
                                                <img src={bolt} style={{width: "40px"}} alt="BOLT"/>
                                            </div>
                                            <div className="col-md-11 col-sm-11">
                                                BOLT: Bietet Taxifahrten und E-Roller im gesamten Stadtgebiet an.
                                                Wenn ihr den Code <code>MV93UV</code> 15€ Rabatt auf eure erste
                                                Fahrt. Die App könnt ihr <a
                                                href={"https://invite.bolt.eu/MV93UV"}
                                                target="_blank" rel="noreferrer">hier</a> herunterladen.
                                            </div>
                                        </li>
                                        <hr/>
                                        <li className="row">
                                            <div className="col-md-1 col-sm-1">
                                                <img src={freenow} style={{width: "40px"}} alt="Free Now"/>
                                            </div>
                                            <div className="col-md-11 col-sm-11">
                                                FreeNow: Bietet Taxifahrten und E-Roller im gesamten Stadtgebiet an.
                                                Wenn ihr den Code <code>5iakynqju</code> 7€ Rabatt für eure erste
                                                Fahrt. Die App könnt ihr <a
                                                href={"https://mytaxi.onelink.me/HySP/qtfab1lr"}
                                                target="_blank" rel="noreferrer">hier</a> herunterladen.
                                            </div>
                                        </li>
                                        <hr/>
                                        <li className="row">
                                            <div className="col-md-1 col-sm-1">
                                                <img src={uber} style={{width: "40px"}} alt="Uber"/>
                                            </div>
                                            <div className="col-md-11 col-sm-11">
                                                Uber: Bietet Taxifahrten im gesamten Stadtgebiet an. Die App könnt
                                                ihr <a
                                                href={"https://ocie.app.link/6qlyRoi7dfb?%243p=a_custom_47734"}
                                                target="_blank" rel="noreferrer">hier</a> herunterladen.
                                            </div>
                                        </li>
                                        <hr/>
                                    </ul>

                                </div>
                            </div>
                            <div className="heading heading-2 col-md-12 col-sm-6">
                                <h4>Unterkünfte</h4>
                                <div className="gta-content">
                                    <p>Auch wenn wir es gerne gewollt hätten, können wir euch leider keine Unterküfte
                                        direkt zur Verfüguing stellen, dennoch möchten wir euch ein paar Empfehlungen
                                        geben die euch bei der Hotelwahl helfen sollten.</p>
                                    <br/>
                                    <p> Das Hotel, welches der Hochzeitslocation am nächsten ist, ist das <b>Hotel am
                                        Borsigturm</b>. Auch wir werden dort unterkommen. In diesem Hotel haben wir ein
                                        Kontigent
                                        von 15 Zimmern zum Preis von <b>99€ inklusive Frühstück</b> pro Nacht
                                        reserviert. Sagt dafür bei der telefonischen Buchung einfach das Stichwort
                                        "Olga&Johannes10092022".
                                        <br/><br/>
                                        <img src={hab} alt="Hotel am Borsigturm"/>
                                        Reservieren: <a
                                            href={"https://direct-book.com/properties/hotelamborsigturmdirect"}
                                            target="_blank" rel="noreferrer">Online</a> oder telefonisch unter
                                        030/43036000
                                    </p>
                                    <br/><br/>
                                    <p>Ansonsten würden wir Hotels empfehlen, die sich entlang der U-Bahnlinie 6
                                        befinden.<br/> Dazu zählen zum Beispiel: <a
                                            href="https://www.booking.com/hotel/de/alt-tegel.de.html"
                                            target="_blank" rel="noreferrer">Hotel Alt-Tegel</a>, <a
                                            href="https://all.accor.com/hotel/5341/index.de.shtml?utm_campaign=seo+maps&utm_medium=seo+maps&utm_source=google+Maps"
                                            target="_blank" rel="noreferrer">Mercure Hotel Berlin City</a>, <a
                                            href="https://www.h-hotels.com/de/hplus/hotels/hplus-hotel-berlin-mitte?utm_source=yext&utm_medium=listing"
                                            target="_blank" rel="noreferrer">H+ Hotel Berlin Mitte</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Getting;