import React, {useEffect, useState} from 'react';
import SectionTitle from '../section-title'
import './style.css'
import {useCookies} from "react-cookie";

const Rsvp = () => {
    const [invite, setInvite] = useState({});
    const [inviteLoaded, setInviteLoaded] = useState(false);
    const [chairOption, setChairOption] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['session']);
    const fetchInvite = async () => {
        try {
            let response = await fetch('/api/invite', {
                credentials: "include"
            });
            let json = await response.json();
            return {success: true, data: json};
        } catch (error) {
            console.log(error);
            return {success: false};
        }
    }

    const saveInvite = async (invite) => {
        try {
            setInviteLoaded(false);
            const url = `/api/invite/${invite.code}`
            const rawResponse = await fetch(url, {
                method: 'PUT',
                credentials: "include",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(invite)
            });
            const json = await rawResponse.json();
            const res = {success: true, data: json};
            if (res.success) {
                setInvite(res.data);
                setInviteLoaded(true);
                alert("Antwort wurde gespeichert!")
                console.log(res.data);
            }
        } catch (error) {
            console.log(error);
            return {success: false};
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setInviteLoaded(false);
                let res = await fetchInvite();
                if (res.success) {
                    setInvite(res.data);
                    setInviteLoaded(true);
                    console.log(res.data);
                }
            } catch (e) {
                alert("Fehler beim laden, bitte versuch es später nochmal.")
                removeCookie("session");
                localStorage.clear();
            }
        })();
    }, []);

    const handleInputChange = (event, idx) => {
        const newInvite = {...invite}
        console.log(event.target)
        if (event.target.id.startsWith("rb")) {
            newInvite.guests[idx].accepted = Boolean(Number(event.target.value))
        }

        if (event.target.id.startsWith("ch")) {
            newInvite.guests[idx].chairOption = event.target.value
        }

        setInvite(newInvite)
        console.log(Boolean(Number(event.target.value)), invite)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        await saveInvite(invite)
    }

    const loading = () => <div className="d-flex justify-content-center ">
        <div className="spinner-border" role="status">
            <span className="sr-only">Einladung wird geladen...</span>
        </div>
        <div style={{paddingLeft: "10px"}}>
            Einladung wird geladen...
        </div>
    </div>

    const getInfoText = () => {
        let text = ""
        const isGroup = invite.guests.length > 1
        const addingAllowed = invite.addingAllowed

        if (isGroup) {
            text += "Bitte gebt uns hier Bescheid, ob ihr zu unserer Hochzeit kommen könnt. Außerdem interessiert uns ob ihr irgendwelche Unverträglichkeiten oder Allergien habt, die wir beim Buffet berücksichtigen sollten. "
            if (addingAllowed) {
                text += "Möchtet ihr noch eine Person mitbringen wollen, könnt ihr diese gerne unten hinzufügen. "
            }
        } else {
            text += "Bitte gib uns hier Bescheid, ob ihr zu unserer Hochzeit kommen könnt. Außerdem interessiert uns ob du irgendwelche Unverträglichkeiten oder Allergien habt, die wir beim Buffet berücksichtigen sollten. "
            if (addingAllowed) {
                text += "Möchtest du noch eine Person mitbringen wollen, kannst du diese gerne unten hinzufügen. "
            }
        }
        text += "Ganz wichtig, nicht vergessen unten auf SPEICHERN zu klicken! 🥳"

        return text
    }

    const renderGuest = (guest, idx) => <div key={guest.name + idx}>
        <h3>Gast Nr. {idx + 1}: {guest.name}</h3>
        <div className="col-12 col-sm-12">
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio"
                       id={`rb_${idx}_yes`} checked={guest.accepted === true} value="1"
                       onChange={(e) => handleInputChange(e, idx)}/>
                <label className="form-check-label" htmlFor={`rb_${idx}_yes`}>
                    Bin dabei 😀
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio"
                       id={`rb_${idx}_no`} value="0" checked={guest.accepted === false}
                       onChange={(e) => handleInputChange(e, idx)}/>
                <label className="form-check-label" htmlFor={`rb_${idx}_no`}>
                    Bin leider <b>nicht</b> dabei 😐
                </label>
            </div>
        </div>
        {chairSelect(guest, idx)}
        <hr/>
    </div>

    const chairSelect = (guest, idx) => {
        if (!guest.isChild)
            return null

        return <div className="col-12 col-sm-12">
            <br/>
            <h6>{guest.name} ist ja ein Kind. Deswegen würden wir gerne wissen, wie er/sie am Besten sitzt: </h6>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio"
                       id={`ch_${idx}_bc`} checked={guest.chairOption === "baby_chair"} value="baby_chair"
                       onChange={(e) => handleInputChange(e, idx)}/>
                <label className="form-check-label" htmlFor={`ch_${idx}_bc`}>
                    Kinderstuhl
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio"
                       id={`ch_${idx}_c`} checked={guest.chairOption === "lap"} value="lap"
                       onChange={(e) => handleInputChange(e, idx)}/>
                <label className="form-check-label" htmlFor={`ch_${idx}_c`}>
                    Sitzt auf dem Schoß
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio"
                       id={`ch_${idx}_l`} checked={guest.chairOption === "chair"} value="chair"
                       onChange={(e) => handleInputChange(e, idx)}/>
                <label className="form-check-label" htmlFor={`ch_${idx}_l`}>
                    Eigener Platz
                </label>
            </div>
        </div>
    }

    function addGuestHandler(event) {
        if (event.target.name.startsWith("new_child")) {
            setChairOption(event.target.value === "1")
        }
    }

    function handleGuestSubmit(event) {
        event.preventDefault()
        const isChild = Boolean(Number(event.target.new_child.value))
        const newGuest = {
            name: event.target.new_name.value,
            accepted: true,
            isManuallyAdded: true,
            isChild: isChild
        }
        if (isChild) {
            newGuest.chairOption = event.target.new_chair.value
        }

        const newInvite = {...invite}
        invite.guests.push(newGuest)
        setInvite(newInvite)

        alert(`${event.target.new_name.value} wurde erfolgreich hinzugefügt`)
        event.target.reset()
    }

    const addGuest = () => <div>
        <hr/>
        <h3>Weiteren Gast hinzufügen</h3>
        <form className="col-12" onSubmit={handleGuestSubmit}>
            <div className="form-group row">
                <label className="col-4 col-form-label" htmlFor="new_name">Name des Gastes</label>
                <div className="col-8">
                    <div className="input-group">
                        <input id="new_name" name="new_name" type="text" className="form-control" required="required"/>
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="new_child" className="col-4 col-form-label">Ist der Gast ein Kind?</label>
                <div className="col-8">
                    <select id="new_child" name="new_child" className="custom-select" required="required"
                            onChange={addGuestHandler}>
                        <option value="0">Nein</option>
                        <option value="1">Ja</option>
                    </select>
                </div>
            </div>
            <div className={`form-group row ${chairOption ? "" : "d-none"}`}>
                <label htmlFor="new_chair" className="col-4 col-form-label">Wie sitzt das Kind?</label>
                <div className="col-8">
                    <select id="new_chair" name="new_chair" className="custom-select">
                        <option value="chair">Auf einem eigenen Stuhl</option>
                        <option value="lap">Auf dem Schoß der Eltern</option>
                        <option value="baby_chair">Einem Kindersitz, der von der Location bereitgestellt wird</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <div className="offset-4 col-8">
                    <button name="submit" type="submit" className="btn btn-primary">Gast hinzufügen</button>
                </div>
            </div>
        </form>
        <hr/>
    </div>


    const form = () => <div>
        <form id="guestform" onSubmit={(event) => handleSubmit(event)}>
            <h4>{invite.greeting} {invite.groupName}!</h4>
            <p>{getInfoText()}</p>
            {invite.guests.map((guest, idx) => renderGuest(guest, idx))}
            <div className="form-group row">
                <label htmlFor="textarea" className="col-4 col-form-label">Weitere Hinweise (Allergien,
                    Unverträglichkeiten, Wünsche, Nachricht an uns)</label>
                <div className="col-8">
                    <textarea id="textarea" name="message" defaultValue={invite.message} cols="40" rows="5"
                              className="form-control"/>
                </div>
            </div>
        </form>
        {invite.addingAllowed ? addGuest() : null}
        <div className="col-12 text-center">
            <button className="submit btn" type="submit" form="guestform">Speichern</button>
        </div>
    </div>


    return (
        <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
            <SectionTitle section={'Seid unsere Gäste'}/>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="rsvp-wrap">
                            {inviteLoaded ? form() : loading()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rsvp;