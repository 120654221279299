import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'

const Welcome = (props) => {

    return (
        <div className={`welcome-area ${props.welcome}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Willkommen zu unserem großen Tag</h2>
                            <p>Ein Jahr, sechs Monate und vier Tage nach unserer Standesamtlichen Hochzeit, möchten wir
                                uns nun noch einmal das Ja-Wort in der Kirche geben und dies anschließend mit Euch
                                zusammen feiern! Diese Website soll dafür einige interessante Informationen
                                zusammentragen!</p>
                            <div className="btn"><AnchorLink href='#rsvp'>Zu-/Absagen</AnchorLink></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Welcome;