import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import SectionTitle from '../section-title'
import herz_jesu from '../../images/events/herz_jesu.jpg'
import sp_tegel from '../../images/events/sp_tegel.jpg'

import './style.css'

const Location = () => {

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    return (
        <div id="event" className="event-section section-padding">
            <SectionTitle section={'Wann & Wo'}/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={herz_jesu} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Kirchliche Trauung</h3>
                                                    <span>Samstag, 10. September 2022, 13:00 Uhr</span>
                                                    <span>Herz-Jesu Kirche, Brunowstraße 37, 13507 Berlin</span>
                                                    <p>Die katholische Herz-Jesu-Kirche ist ein mit
                                                        roten Ziegeln verblendeter Mauerwerksbau mit seitlich
                                                        quadratischem Turm. Der Grundstein zur Kirche wurde am 14.
                                                        August 1904 durch Erzpriester Kuborn aus Lichtenberg gelegt; die
                                                        Festansprache hielt Kaplan Bernhard Lichtenberg. Die feierliche
                                                        Benediktion der Kirche erfolgte am 7. Mai 1905 durch Erzpriester
                                                        Frank Berlin.</p>
                                                    <Button className="btn" onClick={toggle}>Location</Button>
                                                    <Modal isOpen={modal} toggle={toggle}>
                                                        <ModalHeader toggle={toggle}>Anreise</ModalHeader>
                                                        <ModalBody>
                                                            <div className="location-map">
                                                                <iframe title={"Kirche"}
                                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2424.044858394769!2d13.277904417443848!3d52.58688220000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85443a8c42193%3A0xba75efc10eba5f0e!2sPfarrkirche%20Herz%20Jesu!5e0!3m2!1sde!2sde!4v1654527101561!5m2!1sde!2sde"/>
                                                            </div>
                                                            <hr/>
                                                            <div className="location-map">
                                                                <iframe title={"Location"}
                                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2423.8347767008345!2d13.25864031581012!3d52.59068037982766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a8544b1b88bebf%3A0xf43c3eeaf866fa2d!2sSeepavillon!5e0!3m2!1sde!2sde!4v1654529407204!5m2!1sde!2sde"/>
                                                            </div>
                                                        </ModalBody>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>Hochzeits Party</h3>
                                                    <span>Samstag, 10. September 2022, 15:00-03:00 Uhr</span>
                                                    <span>Seepavillon Tegel, Schwarzer Weg 45, 13505 Berlin</span>
                                                    <p>Nur wenige Schritte vom Tegeler See entfernt befindet sich der
                                                        SeePavillon. Dort wo früher im legendären Pavillon du Lac
                                                        gefeiert wurde, entstand 2007 die Eventlocation. Die
                                                        modernen Räumlichkeiten und großzügigen Terrassen werden unsere
                                                        Hochzeit hoffenentlich zu einer tollen Hochzeitsfeier
                                                        beitragen.</p>
                                                    <Button className="btn" onClick={toggle}>Location</Button>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={sp_tegel} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;