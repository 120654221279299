import React from 'react'
import SectionTitle from '../section-title'
import strory1 from '../../images/story/img-1.jpg'
import strory2 from '../../images/story/img-2.jpg'
import strory3 from '../../images/story/img-3.jpg'
import strory4 from '../../images/story/img-4.jpg'
import './style.css'


const Story = () => {
    return (
        <div id="story" className="story-area section-padding">
            <SectionTitle section={'Unsere Geschichte'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={strory1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Erstes Aufeinandertreffen</h3>
                                <span className="date">April 2011</span>
                                <p>Es ist nicht ganz leicht zu sagen, wann wir das erste mal aufeinander getroffen sind,
                                    wir vermuten aber, dass es am letzten Schultag von Johannes war, als er Olgas Schule
                                    einen Besuch abgestattet hat. Das Foto links zeigt auf jeden Fall die erste Facebook
                                    Nachricht die wir uns fast genau 11 Jahre vor der Hochzeit geschrieben haben. Man sieht: Da war von Anfang an Liebe in der Luft!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Unser erstes Date</h3>
                                <span className="date">14. Feb 2014</span>
                                <p>Wann etwas ein Date, oder doch nur ein Treffen ist, lässt sich manchmal schwer sagen.
                                    Definitiv lässt sich aber festhalten, wenn ein Mann eine Frau ein Valentinstag
                                    einlädt und ein Dreigängemenü kocht, sind das schon gute Parameter für ein Date.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory2} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={strory3} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Verlobung</h3>
                                <span className="date">26. Sep 2020</span>
                                <p>Johannes war zu dem Zeitpunkt schon mehr als klar, dass Olga die Frau fürs Leben ist.
                                    Deswegen überlegte er sich einen Plan: Sollte Olga keinen Studienplatz bekommen,
                                    würde er ihr einen Antrag machen, um ihre trauer vergessen zu machen. Sollte sie den
                                    Studienplatz bekommen, hätte sie zwei dinge über die sie sich freuen kann.
                                    Glücklicherweise bekam Olga den Platz wurde in ein Restaurant über den Dächern der
                                    Stadt ausgeführt. Zwischen Hauptspeise und Dessert kam es dann zur Frage der
                                    Fragen.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Standesamtliche Hochzeit</h3>
                                <span className="date">6. Mär 2021</span>
                                <p>Pünktlich zu unserem siebten Jahrestag haben wir dann zu zweit im Standesamt
                                    Zehlendorf geheiratet!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Story;