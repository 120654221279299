import React from 'react';

// components
import HeroMain from '../../components/HeroMain';
import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import Trauzeugen from '../../components/people';
import Location from '../../components/location';
import Rsvp from '../../components/rsvp';
import Getting from '../../components/getting';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import Footer from '../../components/footer'


const HomeMain = () => {
    return(
       <div>
           <Navbar/>
           <HeroMain/>
           <Saveday saveday={'s2'}/>
           <Welcome welcome={'s2'}/>
           <Story/>
           <Location/>
           <Getting/>
           <Rsvp/>
           <Trauzeugen guest={'guest-style'}/>
           <Footer footer={'s2'}/>
       </div>
    )
}

export default HomeMain;