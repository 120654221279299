import React, {Component} from 'react';
import SectionTitle from '../section-title'
import './style.css'

import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";

class LoginForm extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {
        code: '',
        error: {}
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    submitHandler = (e) => {
        e.preventDefault();

        const {code, error} = this.state;


        if (code === '') {
            error.notes = "Bitte gib deinen Einladungscode ein";
        }


        if (error) {
            this.setState({
                error
            })
        }
        if (error.notes === '') {
            this.setState({
                code: '',
                error: {}
            })
        }


        console.log(this.state);
        console.log(this.state.error.notes);
        const {cookies} = this.props

        return fetch(`/api/auth/login?code=${code}`, {credentials: "include"})
            .then(function (response) {
                if (response.status < 400)
                    cookies.set("session", response.data);
                else if(response.status === 401)
                    alert("Ungültiger Einladungscode eingegeben!")
                else
                    alert("Die Website funktioniert gerade nicht richtig, bitte versuch es später nochmal!")
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }

    render() {
        const {code, error} = this.state;
        return (
            <div id="login" className="login-area go-login-area section-padding">
                <SectionTitle section={'Seid unsere Gäste'}/>

                <div className="container">
                    <div className="row">
                        <p align={"center"}>
                            Hallo ihr Lieben, schön dass ihr den Weg auf unsere Homepage gefunden habt. Bitte gebt unten
                            den Einladungscode ein, der auf dem Anhänger der Einladung klebt und drückt dann auf
                            Absenden.
                        </p>
                        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="login-wrap">
                                <form onSubmit={this.submitHandler}>
                                    <div className="contact-form form-style">
                                        <div className="col-12 col-sm-12">
                                            <input type="text" value={code} onChange={this.changeHandler}
                                                   placeholder="Einladungscode" id="code" name="code"/>
                                            <p>{error.name ? error.name : ''}</p>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button id="submit" type="submit" className="submit">Absenden</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withCookies(LoginForm);