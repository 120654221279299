import React, {Component} from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {Link} from 'react-router-dom'
import './style.css';

export default class MobileMenu extends Component {

    state = {
        isMenuShow: false,
        isOpen: false,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }


    render() {

        const { isMenuShow, isOpen } = this.state;

        const logout = async () => {
            try {
                await fetch("/api/auth/logout")
            } finally {
                localStorage.clear();
                window.location.href = '/';
            }
        }

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="" onClick={this.menuHandler}><i className="fa fa-close">Menü schließen</i> </div>
                    <ul className="responsivemenu">
                        <li><AnchorLink href='#home'>Start</AnchorLink></li>
                        <li><AnchorLink href='#story'>Brautpaar</AnchorLink></li>
                        <li><AnchorLink href='#event'>Hochzeitsfeier</AnchorLink></li>
                        <li><AnchorLink href='#transport'>Transport/Hotels</AnchorLink></li>
                        <li><AnchorLink href='#rsvp'>Zu-/Absagen</AnchorLink></li>
                        <li><AnchorLink href='#people'>Trauzeugen</AnchorLink></li>
                        <li><a href='#' onClick={logout}><i className="fa fa-sign-out">Logout</i></a></li>
                    </ul>
                    
                </div>

                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>         
            </div>
        )
    }
}
