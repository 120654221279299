import React, {useEffect, useState} from 'react';
import SectionTitle from '../section-title'
import max from '../../images/groomsmen-bridesmaid/max.jpg'
import peter from '../../images/groomsmen-bridesmaid/peter.jpg'
import lavinia from '../../images/groomsmen-bridesmaid/lavinia.jpg'
import franzi from '../../images/groomsmen-bridesmaid/franzi.jpg'

import './style.css'

const Trauzeugen = () => {
    const [trauzeugen, setTrauzeugen] = useState([]);
    const [trauzeugenLoaded, setTrauzeugenLoaded] = useState(false);
    const fetchUser = async () => {
        try {
            let response = await fetch('/api/trauzeugen', {
                credentials: "include"
            });
            let json = await response.json();
            return {success: true, data: json};
        } catch (error) {
            console.log(error);
            return {success: false};
        }
    }
    useEffect(() => {
        (async () => {
            setTrauzeugenLoaded(false);
            let res = await fetchUser();
            if (res.success) {
                setTrauzeugenLoaded(true);
                setTrauzeugen(res.data["brideMates"]);
            }
        })();
    }, []);

    const images = {
        max,
        peter,
        lavinia,
        franzi
    }

    const loading = () => <div className="d-flex justify-content-center ">
        <div className="spinner-border" role="status">
            <span className="sr-only">Trauzeugen werden geladen...</span>
        </div>
        <div style={{paddingLeft: "10px"}}>
            Trauzeugen werden geladen...
        </div>
    </div>

    const view = () =>
        <div className="Groomsman-wrap">
            <div className="row">
                {trauzeugen.map(helper => {
                    return <div className="col-lg-3 col-md-6 col-sm-6 grid" key={helper.name}>
                        <div className="groomsmen-bridesmaid-wrap">
                            <div className="groomsmen-bridesmaid-img">
                                <img src={images[helper.img]} alt="trauzeuge"/>
                            </div>
                            <div className="groomsmen-bridesmaid-content">
                                <h4>{helper.name}</h4>
                                <span>Tel: {helper.number}</span>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>

    return (
        <section id='people'>
            <div className="groomsmen-bridesmaid-area section-padding">
                <SectionTitle section={'Trauzeugen & Trauzeuginnen'}/>
                <div className="container">
                    <div className="groomsmen-bridesmaid-area-menu">
                        {trauzeugenLoaded ? view() : loading()}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Trauzeugen;