import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import HomeMain from '../HomeMain/index'
import Homepage from '../HomePage/index'
import Scrollbar from '../../components/scrollbar'
import Login from "../login";
import {useCookies} from "react-cookie";


const AllRoute = () => {



    const [cookies] = useCookies(["session"])
    console.error(cookies)

    if (!cookies["session"]) {
        return <Login/>
    }

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path='/' component={HomeMain}/>
                    <Route path='/home' component={HomeMain}/>
                    <Route path='/home2' component={Homepage}/>
                </Switch>
                <Scrollbar/>
            </Router>
        </div>
    );
}

export default AllRoute;
