import React from 'react';

// components
import Saveday from '../../components/countdown'
import LoginForm from "../../components/LoginForm";


const Login = () => {
    return(
        <div>
            <LoginForm/>
            <Saveday saveday={'s2'}/>
        </div>
    )
}

export default Login;